import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IDirectoryMember, IDirectoryMemberResponse } from "./directoryTypes";
import avater from "../../assets/loginImage/avatar2.png";
import { useSearchParams } from "react-router-dom";
import Header from "./Header";
// import { debounce } from "lodash";
// import GlobalLoader from "src/components/SuspenseLoader/GlobalLoader";
import { Box, CircularProgress } from "@mui/material";
import { imgUrl, url } from "../../Helpers/utils/Constant";

const MemberDirectory = () => {
  const [membersData, setMembersData] = useState<
    IDirectoryMember[] | undefined
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const membersPerPage = 50;

  const search: string | null = searchParams.get("searchParams");

  const [loading, setLoading] = useState(false);

  const fetchMemberData = async (page: number) => {
    setLoading(true);
    try {
      const skip = (page - 1) * membersPerPage;
      let uri = `${url}/api/atab/user-member/get/all/by/zone/status/for-admin/all/active?skip=${skip}&limit=${membersPerPage}`;
      if (search) {
        // setTimeout(() => {
        uri += `&searchParams=${search.trim()}`;
        // }, 2000);
      }

      const { data } = await axios.get<IDirectoryMemberResponse>(uri);
      if (data.success) {
        setMembersData(data.data);
        setTotal(data?.total || 0);
        setTotalPages(Math.ceil(total / membersPerPage));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemberData(currentPage);
  }, [currentPage, total, search]);

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getItemProps = (index: number) => ({
    onClick: () => setCurrentPage(index),
    className: `rounded-md px-2.5 py-1 ${
      currentPage === index ? "bg-amber-400 text-white" : ""
    }`,
  });

  // const handelChangeInput = debounce((value) => {
  //   setSearchParams({ searchParams: value });
  // }, debounceTime);
  return (
    <>
      <Header />
      {/* Banner Section  */}
      <section className="bg-[#131B88] mt-[-3rem]">
        <div className="container mx-auto px-4 md:px-8 text-white py-32 text-center space-y-5 ">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl">
            Membership-Directory
          </h1>
          <p className="text-xl lg:text-2xl font-medium">
            Home | Membership-Directory
          </p>
        </div>
      </section>
      {/* Search Section  */}
      <section className="container mx-auto px-4 md:px-8 py-4 bg-gray-200 rounded-md my-6">
        <fieldset className="flex gap-2 w-full text-gray-100">
          <div className="flex w-full">
            <input
              type="search"
              name="member"
              value={String(search)}
              placeholder="Search by agency/company name"
              onChange={(e) =>
                setSearchParams({ searchParams: e.target.value })
              }
              className="w-full px-5 py-4 text-sm bg-gray-100 text-gray-700 border mx-auto focus:outline-none"
            />
            {/* <button
              type="button"
              title="search"
              className="bg-amber-400 hover:bg-amber-600 px-5 py-4 right-2"
            >
              <svg
                fill="currentColor"
                viewBox="0 0 512 512"
                className="w-4 h-4 text-gray-100"
              >
                <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
              </svg>
            </button> */}
          </div>
        </fieldset>
      </section>
      {/* Members Data Section  */}
      <section className="container mx-auto px-4 md:px-8 my-6 space-y-5">
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={64} disableShrink thickness={3} />
          </Box>
        ) : (
          <>
            {membersData?.length ? (
              <>
                {" "}
                {membersData?.map(
                  ({
                    user_member_representative_photo,

                    user_member_company_name,
                    user_member_representative_name,
                    company_owner_designation,
                    addressline1,
                    phone,
                    cellphone,
                    email,
                    user_member_id_card_number,
                    member_company_website,
                  }) => (
                    <div className="flex flex-col lg:flex-row">
                      <div className="border-b-4 border-amber-400 grid grid-cols-12 w-full">
                        <div className="col-span-12 md:col-span-4 lg:col-span-2">
                          <img
                            src={
                              user_member_representative_photo
                                ? `${imgUrl}/${user_member_representative_photo}`
                                : avater
                            }
                            alt="Person"
                            className="object-cover size-[170px] border-4 lg:border-b-0 border-amber-400"
                          />
                        </div>
                        <div className="col-span-12 md:col-span-8 lg:col-span-6 flex flex-col justify-between">
                          <h2 className="bg-gradient-to-r from-amber-600 to-amber-400 px-3 py-1 text-2xl tracking-wide font-bold text-white">
                            {user_member_company_name}
                          </h2>
                          <div>
                            <div className="px-3">
                              <p className="font-bold text-lg">
                                {user_member_representative_name}
                              </p>
                              <p>{company_owner_designation}</p>
                              <p className="mt-2">{addressline1}</p>
                            </div>
                            <span className="p-1 bg-amber-600 text-white font-medium md:float-right block md:inline">
                              {user_member_id_card_number}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-12 lg:col-span-4 bg-red-100 py-4 px-4 space-y-1">
                          <p>Phone: {phone}</p>
                          <p>Mobile: {cellphone}</p>
                          <p>E-mail: {email}</p>
                          <p>
                            Web:{" "}
                            <a
                              href={member_company_website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {member_company_website}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <div className="flex justify-center items-center text-base bg-[#97bce1]">
                <p className="py-1">No matching records found</p>
              </div>
            )}
          </>
        )}
      </section>
      {/* Pagination Section */}

      {total > 50 && (
        <section className="flex justify-between container mx-auto px-4 md:px-8 my-6">
          <p>
            Showing {(currentPage - 1) * membersPerPage + 1} to{" "}
            {Math.min(currentPage * membersPerPage, total)} of {total} entries
          </p>
          <div className="flex items-center gap-4">
            <button
              className="flex items-center gap-2 rounded-full"
              onClick={prev}
              disabled={currentPage === 1}
            >
              <FaArrowLeft strokeWidth={2} className="h-4 w-4" /> Previous
            </button>
            <div className="flex items-center gap-2">
              {totalPages > 1 && (
                <>
                  <button {...getItemProps(1)}>1</button>
                  {currentPage > 3 && <span>...</span>}
                  {currentPage > 2 && (
                    <button {...getItemProps(currentPage - 1)}>
                      {currentPage - 1}
                    </button>
                  )}
                  {currentPage !== 1 && currentPage !== totalPages && (
                    <button
                      {...getItemProps(currentPage)}
                      className="bg-amber-400 text-white rounded-md px-2.5 py-1"
                    >
                      {currentPage}
                    </button>
                  )}
                  {currentPage < totalPages - 1 && (
                    <button {...getItemProps(currentPage + 1)}>
                      {currentPage + 1}
                    </button>
                  )}
                  {currentPage < totalPages - 2 && <span>...</span>}
                  {totalPages > 1 && (
                    <button {...getItemProps(totalPages)}>{totalPages}</button>
                  )}
                </>
              )}
            </div>
            <button
              className="flex items-center gap-2 rounded-full"
              onClick={next}
              disabled={currentPage === totalPages}
            >
              Next
              <FaArrowRight strokeWidth={2} className="h-4 w-4" />
            </button>
          </div>
        </section>
      )}

      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default MemberDirectory;
