import { Dispatch, SetStateAction, useState } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { url } from "../../Helpers/utils/Constant";
import { getToken } from "../../rest/utils/get-token";
import { showToast } from "../../Toaster/Toaster";

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: Function;
};

const CreateRenewalApplication = ({ handelClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const token = getToken();

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === "object") {
        formData.append(item, data[item][0]);
      }
    });
    formData.append(
      "renewal_application_member_id",
      user.user_member_id?.toString()
    );

    try {
      fetch(`${url}/api/member/renewal-application`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token ? token : ""}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setLoading(false);
            handelClose(false);
            setReload();
          } else {
            setLoading(false);
          }
        });
    } catch (error: any) {
      showToast(`${error?.response?.data?.message}`, "error");
      setLoading(false);
    }

    console.table(Object.fromEntries(formData));

    // createData(formData);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={"sm"}>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Updated civil aviation file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_updated_civil_aviation_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Updated trade license file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_updated_trade_license_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="TAX Return certificate file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_tax_return_certificate_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Previous Certificate file from ATAB ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_prev_atab_certificate_file")}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Loading..."
              variant="contained"
              fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateRenewalApplication;
