import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { destroyCookie } from "nookies";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuthContext } from "../Context/AuthContext/AuthContext";
import { AUTH_USER_FAILED, imgUrl } from "../Helpers/utils/Constant";
import Notifications from "../Components/Notifications/Notifications";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";

const DashboardAppBar = ({ drawerWidth, handleDrawerToggle }: any) => {
  const queryClient = useQueryClient();
  const { user, dispatch: authDispatch } = useAuthContext();
  const { user_member_company_name, user_member_id } = user;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    destroyCookie(null, "atab_ua");

    queryClient.clear();
    queryClient.resetQueries();
    // queryClient.resetMutationCache();
    authDispatch?.({
      type: AUTH_USER_FAILED,
    });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#0c4da2",
          // zIndex: 0,
        }}
      >
        <Toolbar sx={{ ml: 2 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            mx={1}
          >
            <Box>
              <Typography variant="h6" noWrap component="div">
                Dashboard
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Badge
                badgeContent={`${
                  user.user_member_account_status === "Application Inspection"
                    ? "Office Inspection"
                    : user.user_member_account_status.charAt(0).toUpperCase() +
                      user.user_member_account_status.slice(1)
                }`}
                color={`${
                  user.user_member_account_status === "active"
                    ? "success"
                    : "warning"
                }`}
                sx={{
                  "& .MuiBadge-badge": {
                    padding: "13px",
                    fontSize: "12px",
                  },
                  mr: 2,
                }}
              />
              <Box sx={{ cursor: "pointer", position: "relative" }} pt={1}>
                <Notifications />
              </Box>

              <Box>
                <Tooltip title={user_member_company_name || ""}>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="user"
                      src={
                        user?.user_member_representative_photo
                          ? `${imgUrl}/${user?.user_member_representative_photo}`
                          : "/static/images/avatar/2.jpg"
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Link to="/profile">
                    <MenuItem onClick={() => handleCloseUserMenu()}>
                      <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                  </Link>
                  {user_member_id && (
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        handleLogout();
                      }}
                    >
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default DashboardAppBar;
