import { useState, useEffect } from "react";
import {
  IInvoiceItem,
  IModalTypes,
  IResponseSingleInvoice,
  ISingleInvoice,
} from "../../Types/Payments/PaymentsTypes";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { getStatusLabel } from "../../Utils/Label/status-label";
import { HttpClient } from "../../rest/utils/http";

const style = {
  position: "absolute",
  top: { xs: "40%", md: "50%", xl: "40%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: "50%" },
  borderRadius: "5 bpx",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PaymentDetailsModal = ({
  open,
  handleClose,
  inVoiceId,
  user,
}: IModalTypes) => {
  const [singleInvoice, setSingleInvoice] = useState<ISingleInvoice>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (inVoiceId) {
      try {
        (async function () {
          const res: IResponseSingleInvoice = await HttpClient.get(
            `/api/atab/invoice/get/single/${inVoiceId}`
          );
          if (res.success) {
            setSingleInvoice(res.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })();
      } catch (error) {
        setLoading(false);
      }
    }
  }, [inVoiceId]);

  const {
    invoiceItems,
    atab_payment_invoice_grand_total,
    atab_payment_invoice_issue_date,
  } = singleInvoice || {};

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "center", pb: 3 }}>
            <Typography id="modal-modal-title" variant="h3">
              Invoice details
            </Typography>
          </Box>

          {loading ? (
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <Box>
                {invoiceItems?.length && (
                  <Box>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell align="center">Check No</TableCell> */}
                            <TableCell align="center">Paid for</TableCell>
                            <TableCell align="center">Year</TableCell>
                            <TableCell align="center">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoiceItems?.map(
                            (sItem: IInvoiceItem, index: number) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">
                                    {sItem.atab_payment_invoice_item_name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {sItem.atab_payment_invoice_item_start_year}
                                  </TableCell>
                                  {/* <TableCell align="center">
                                    {sItem.atab_payment_invoice_item_paymentable_item_id ===
                                    1 ? (
                                      <>
                                        {moment(
                                          atab_payment_invoice_issue_date
                                        ).format("YYYY")}
                                      </>
                                    ) : (
                                      <>
                                        {sItem?.atab_payment_invoice_item_start_year &&
                                        sItem?.atab_payment_invoice_item_end_year ? (
                                          <>
                                            {" "}
                                            {
                                              sItem?.atab_payment_invoice_item_start_year
                                            }
                                            -{" "}
                                            {
                                              sItem?.atab_payment_invoice_item_end_year
                                            }
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </TableCell> */}
                                  <TableCell align="center">
                                    {sItem.atab_payment_invoice_item_amount}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
              <Box className="company-info" pt={3}>
                <ul>
                  <li>
                    <span>Total amount</span>:
                    <p>{atab_payment_invoice_grand_total} tk</p>
                  </li>
                  {/* <li>
                    <span>Invoice status </span>:{" "}
                    <span style={{ marginLeft: "8px" }}>
                      {" "}
                      {getStatusLabel(atab_payment_invoice_status!)}
                    </span>
                  </li> */}
                  {/* <li>
                    <span>Representative name</span>:
                    <p>{user_member_representative_name}</p>
                  </li> */}
                  <li>
                    <span>Email</span>:<p>{user.user_member_email} </p>
                  </li>
                  <li>
                    <span>Date</span>:
                    <p>
                      {moment(atab_payment_invoice_issue_date).format(
                        "Do MMM YYYY"
                      )}
                    </p>
                  </li>

                  <li>
                    <span>Phone number </span>:<p>{user.user_member_phone} </p>
                  </li>
                </ul>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentDetailsModal;
