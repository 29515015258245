import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getStatusLabel } from "../../Utils/Label/status-label";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import CreateAddressChangeApplication from "./CreateAddressChangeApplication";
import { useAddressChangeQuery } from "../../rest/useAddressChange";
import { IChangeAddress } from "../../rest/types";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import TableNoDataMessage from "../../Components/TableMessage/TableNoDataMessage";

interface Data {
  address_change_application_id: number;
  user_member_company_name: string;
  address_change_application_status: string;
  address_change_application_created_at: string;
  user_member_representative_photo: string;
  action?: string;
}

function createData(
  address_change_application_id: number,
  user_member_company_name: string,
  address_change_application_status: string,
  address_change_application_created_at: string,
  user_member_representative_photo: string
): Data {
  return {
    address_change_application_id,
    user_member_company_name,
    address_change_application_status,
    address_change_application_created_at,
    user_member_representative_photo,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "address_change_application_id",
    numeric: false,
    disablePadding: false,
    label: "Application ID",
  },
  {
    id: "address_change_application_status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "address_change_application_created_at",
    numeric: false,
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ setOpen }: { setOpen: any }) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", p: 2 }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Address change applications
      </Typography>

      <Tooltip sx={{ mr: 2 }} title="Create address change application">
        <IconButton onClick={() => setOpen(true)}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export default function AddressChangeApplication() {
  const { user } = useAuthContext();
  const { data, fetchData, loading } = useAddressChangeQuery();
  const [open, setOpen] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    fetchData({ address_change_application_member_id: user.user_member_id });
  }, [reload, user?.user_member_id, fetchData]);

  const rows = data.map((row: IChangeAddress) =>
    createData(
      row.address_change_application_id,
      row.user_member_company_name,
      row.address_change_application_status,
      row.address_change_application_created_at,
      row.user_member_representative_photo
        ? row.user_member_representative_photo
        : "public/logo.png"
    )
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: "100%", mt: 5 }}>
        <Paper sx={{ width: "100%", mb: 2, mt: 5 }}>
          {user?.user_member_account_status === "active" && (
            <EnhancedTableToolbar setOpen={setOpen} />
          )}
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={"medium"}>
              <EnhancedTableHead />
              <TableBody>
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.user_member_company_name}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        // component="th"
                        id={labelId}
                        // scope="row"
                        align="left"
                      >
                        {row.address_change_application_id}
                      </TableCell>
                      <TableCell align="left">
                        {getStatusLabel(row.address_change_application_status)}
                      </TableCell>
                      <TableCell align="left">
                        {moment(
                          `${row.address_change_application_created_at}`
                        ).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title="View">
                          <Link
                            to={`/address-change-applications/${row.address_change_application_id}`}
                          >
                            <IconButton>
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!loading && rows.length === 0 && <TableNoDataMessage />}
                {loading && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell align="center" colSpan={6}>
                      Loading...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ModalsWrapper
        modalData={{ title: "Create address change application" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <CreateAddressChangeApplication
          setReload={setReload}
          handelClose={setOpen}
        />
      </ModalsWrapper>
    </>
  );
}
