import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useArea,
  useDistrict,
  useDivision,
  useThana,
} from "../../rest/useAddress";
import { Dispatch, SetStateAction, useState } from "react";
import { ICompanyDetails } from "../../rest/types";

type IProps = {
  division_id: number;
  district_id: number;
  thana_id: number;
  area_id: number;
};

type IStateProps = {
  setAreaID: Dispatch<SetStateAction<string>>;
  companyData?: ICompanyDetails;
};

function AddressSelect({ setAreaID, companyData }: IStateProps) {
  const [addressID, setAddressID] = useState<IProps>({
    division_id: companyData?.division_id ?? 0,
    district_id: companyData?.district_id ?? 0,
    thana_id: companyData?.thana_id ?? 0,
    area_id: companyData?.area_id ?? 0,
  });
  const { division, isLoading } = useDivision();
  const { district, isLoading: districtLoading } = useDistrict({
    division_id: addressID.division_id,
  });
  const { thana, isLoading: thanaLoading } = useThana({
    division_id: addressID.division_id,
    district_id: addressID.district_id,
  });
  const { area, isLoading: areaLoading } = useArea({
    division_id: addressID.division_id,
    district_id: addressID.district_id,
    thana_id: addressID.thana_id,
  });

  return (
    <>
      <Grid xs={12} md={4} lg={4} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select division</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select division"
            defaultValue={companyData?.division_id}
            onChange={(e) =>
              setAddressID({
                ...addressID,
                division_id: Number(e.target.value),
              })
            }
          >
            {isLoading && <MenuItem>Loading...</MenuItem>}
            {!isLoading && division.length === 0 && (
              <MenuItem>Not found</MenuItem>
            )}
            {division.length > 0 &&
              division.map((item, i) => (
                <MenuItem key={i + 1} value={item.division_id}>
                  {item.division_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} lg={4} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select district</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select district"
            defaultValue={companyData?.district_id}
            onChange={(e) =>
              setAddressID({
                ...addressID,
                district_id: Number(e.target.value),
              })
            }
          >
            {districtLoading && <MenuItem>Loading...</MenuItem>}
            {!isLoading && district.length === 0 && (
              <MenuItem>Not found</MenuItem>
            )}
            {district.length > 0 &&
              district.map((item, i) => (
                <MenuItem key={i + 1} value={item.district_id}>
                  {item.district_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} lg={4} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select thana</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select thana"
            defaultValue={companyData?.thana_id}
            onChange={(e) =>
              setAddressID({
                ...addressID,
                thana_id: Number(e.target.value),
              })
            }
          >
            {thanaLoading && <MenuItem>Loading...</MenuItem>}
            {!isLoading && thana.length === 0 && <MenuItem>Not found</MenuItem>}
            {thana.length > 0 &&
              thana.map((item, i) => (
                <MenuItem key={i + 1} value={item.thana_id}>
                  {item.thana_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={12} md={4} lg={4} item>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select area</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select area"
            defaultValue={String(companyData?.area_id)}
            onChange={(e: SelectChangeEvent<string>) =>
              setAreaID(e.target.value)
            }
          >
            {areaLoading && <MenuItem>Loading...</MenuItem>}
            {!isLoading && area.length === 0 && <MenuItem>Not found</MenuItem>}
            {area.length > 0 &&
              area.map((item, i) => (
                <MenuItem key={i + 1} value={item.area_id}>
                  {item.area_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default AddressSelect;
