import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSingleAddressQuery } from "../../rest/useAddressChange";
import { getStatusLabel } from "../../Utils/Label/status-label";
import PageLoader from "../../Components/Spinner/PageLoader";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../Components/pdfAndImageView/CommonTypoGraphy";

const SingleAddressApplication = () => {
  const { id } = useParams();
  const { data, fetchData, loading } = useSingleAddressQuery(
    id !== undefined ? id : ""
  );
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading ? (
        <Container sx={{ pt: 5 }}>
          <Card>
            <CardContent>
              {data ? (
                <Grid
                  container
                  pt={2}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application ID:
                    </Typography>
                    {data.address_change_application_id}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application Date:
                    </Typography>
                    {moment(data.address_change_application_created_at).format(
                      "MMM Do YY"
                    )}
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Company name:
                    </Typography>
                    {data.user_member_company_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Address line:
                    </Typography>
                    {data.address_change_application_addressline}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Postal code:
                    </Typography>
                    {data.address_change_application_postal_code}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Police station:
                    </Typography>
                    {data.address_change_application_police_station}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {getStatusLabel(data.address_change_application_status)}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Civil Aviation"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          data?.address_change_application_civil_aviation
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Trade License"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          data?.address_change_application_trade_license
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    {" "}
                    <CommonTypoGraphy
                      name="View Forwarding letter"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(data?.address_change_application_form);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Division:
                    </Typography>
                    {data.division_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      District:
                    </Typography>
                    {data.district_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Thana:
                    </Typography>
                    {data.thana_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Area name:
                    </Typography>
                    {data.area_name}
                  </Grid>
                  {data.address_change_application_reject_reason ? (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Note:
                      </Typography>
                      {data.address_change_application_reject_reason}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  No Data found
                </Typography>
              )}
            </CardContent>
          </Card>
        </Container>
      ) : (
        <PageLoader />
      )}{" "}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default SingleAddressApplication;
