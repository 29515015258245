import { Card, CardContent, Grid, Typography, CardHeader } from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRenewalSingleQuery } from "../../rest/useFetchRenewalApplication";
import PageLoader from "../../Components/Spinner/PageLoader";
import { getStatusLabel } from "../../Utils/Label/status-label";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../Components/pdfAndImageView/CommonTypoGraphy";

const SingleRenewalApplication = () => {
  const { id } = useParams();
  const {
    data: application,
    fetchData,
    loading,
  } = useRenewalSingleQuery(id !== undefined ? id : "");
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const [file, setFile] = useState("");

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {!loading ? (
        <Container sx={{ pt: 5 }}>
          <Card>
            <CardHeader title={`Details #${id}`}></CardHeader>
            <CardContent>
              {application ? (
                <Grid
                  container
                  pt={2}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application Date:
                    </Typography>
                    {moment(application.renewal_application_created_at).format(
                      "MMM Do YY"
                    )}
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Company name:
                    </Typography>
                    {application.user_member_company_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {getStatusLabel(application.renewal_application_status)}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{
                        pr: 1,
                        cursor: "pointer",
                        color:
                          file ===
                          application.renewal_application_updated_civil_aviation_file
                            ? "#0000EE"
                            : "",
                      }}
                      gutterBottom
                      onClick={() =>
                        setFile(
                          application.renewal_application_updated_civil_aviation_file
                        )
                      }
                    >
                      Download Civil Aviation
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    {" "}
                    <CommonTypoGraphy
                      name="View Trade License"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          application?.renewal_application_updated_trade_license_file
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Tax Return"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          application?.renewal_application_tax_return_certificate_file
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View Previous Certificate"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          application?.renewal_application_prev_atab_certificate_file
                        );
                      }}
                    />
                  </Grid>
                  {application.renewal_application_reject_reason ? (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Note:
                      </Typography>
                      {application.renewal_application_reject_reason}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  No Data found
                </Typography>
              )}
            </CardContent>
          </Card>
          <>
            <ModalsWrapper
              modalData={{ title: "View document" }}
              setShowModal={setOpen}
              showModal={open}
            >
              <PdfAndImageView file={imageFile} />
            </ModalsWrapper>
          </>
        </Container>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default SingleRenewalApplication;
