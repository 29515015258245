import {
  CardContent,
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import PageLoader from "../../../Components/Spinner/PageLoader";
import { ICompanyOwnerList } from "../../../rest/types";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import UpdateCompanyOwner from "../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyOwner";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

type IProps = {
  data: ICompanyOwnerList[];
  loading: boolean;
  isApplied: number;
};

const CardCompanyOwner = ({ data, loading, isApplied }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  const [toggle, setToggle] = useState(false);
  console.log(data);
  return (
    <>
      {!loading ? (
        data?.map((item: ICompanyOwnerList, index: number) => {
          return toggle ? (
            <Box key={index}>
              <UpdateCompanyOwner singleOwner={item} setToggle={setToggle} />
            </Box>
          ) : (
            <Box key={index}>
              <CardContent sx={{ position: "relative" }}>
                <Grid
                  container
                  // pt={2}
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Name :
                    </Typography>
                    {item.company_owner_name}{" "}
                    {item?.company_representative ? (
                      <b>(Will represent organization)</b>
                    ) : (
                      ""
                    )}
                  </Grid>{" "}
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Designation :
                    </Typography>
                    {item.company_owner_designation}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Email :
                    </Typography>
                    {item.company_owner_email}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Address :
                    </Typography>
                    {item.company_owner_address}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Phone no :
                    </Typography>
                    {item.company_owner_phone}
                  </Grid>
                  {item.company_owner_biodata && (
                    <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View bio-data"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.company_owner_biodata);
                        }}
                      />
                    </Grid>
                  )}
                  {item.company_owner_photo && (
                    <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View photo"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.company_owner_photo);
                        }}
                      />
                    </Grid>
                  )}
                  {item.company_owner_passport && (
                    <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View Trade License"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.company_owner_passport);
                        }}
                      />
                    </Grid>
                  )}
                  {item.company_owner_signature && (
                    <Grid item xs={2} sm={4} md={4}>
                      {" "}
                      <CommonTypoGraphy
                        name="View signature"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.company_owner_signature);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      NID number:
                    </Typography>
                    {item.company_owner_nid}
                  </Grid>{" "}
                  {item.company_owner_nid_file && (
                    <Grid item xs={2} sm={4} md={4}>
                      {" "}
                      <CommonTypoGraphy
                        name="View NID file"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.company_owner_nid_file);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                {!isApplied && (
                  <Box sx={{ position: "absolute", top: 0, right: 20 }}>
                    <Tooltip title="Update" sx={{ mr: 2 }}>
                      <IconButton onClick={() => setToggle(!toggle)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                <Divider sx={{ mt: 3 }} />
              </CardContent>
            </Box>
          );
        })
      ) : (
        <PageLoader />
      )}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCompanyOwner;
