import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { showToast } from "../../../Toaster/Toaster";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddressSelect from "../../address-change-application/AddressSelect";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { AddCompanyInfo } from "../../../ReactQuery/Mutations/CompanyAddMutation";

const AddCompanyInformation = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const queryClient = useQueryClient();
  const [date, setDate] = useState("");
  const [zone, setZone] = useState<number>(1);
  const [areaID, setAreaID] = useState<string>("");
  const [companyLogo, setCOmpanyLogo] = useState("");

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return AddCompanyInfo(data);
    },

    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_DETAILS],
      });
    },
  });

  const onSubmit = async (data: any) => {
    data.stablished_date = date;
    data.zone = zone;
    data.company_type = "Travel agency";
    const formData = new FormData();
    formData.append("area_id", areaID);
    formData.append("company_logo", companyLogo);
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    mutation.mutate(formData);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ p: 2 }}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <Box mt={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  sx={{ overflow: "hidden" }}
                  id="demo-simple-select-label"
                >
                  Select zone
                </InputLabel>
                {/* <label
										style={{ marginTop: -20 }}
										htmlFor="demo-simple-select-label"
									>
										Select zone
									</label> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={zone}
                  required
                  label="Select zone"
                  placeholder="Select zone"
                  onChange={(e: any) => setZone(e.target.value)}
                >
                  <MenuItem value={1}>Dhaka</MenuItem>
                  <MenuItem value={2}>Chittagong</MenuItem>
                  <MenuItem value={3}>Sylhet</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
              <label htmlFor="">Organization type</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter organization type"
                inputProps={{
                  readOnly: true,
                }}
                defaultValue={"Travel agency"}
                type="text"
                fullWidth
                // {...register("company_type")}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=""> Organization established date </label>
              <TextField
                id="date"
                type="date"
                required
                value={date}
                onChange={(e) => setDate(e.target.value)}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor="">
                {" "}
                Which police station does the organization belong to?{" "}
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter organization police station"
                type="text"
                required
                fullWidth
                {...register("police_station", {
                  required: "Police station is required",
                })}
                error={errors.police_station && Boolean(errors.police_station)}
                helperText={
                  errors.police_station?.message
                    ? `${errors.police_station?.message}`
                    : ""
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <Box>
              <label htmlFor=""> Organization telephone number </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                required
                placeholder="Enter organization telephone number"
                fullWidth
                {...register("cellphone", {
                  required: "Organization telephone number is required",
                })}
                error={errors.cellphone && Boolean(errors.cellphone)}
                helperText={
                  errors?.cellphone?.message
                    ? `${errors?.cellphone?.message}`
                    : ""
                }
              />
            </Box>
            <Box mt={2}>
              <label htmlFor="">
                Organization mobile number{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                {...register("phone", {
                  required: "Organization mobile number is required",
                  pattern: {
                    value: /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/,
                    message: "Invalid phone number",
                  },
                })}
                error={errors.phone && Boolean(errors.phone)}
                helperText={
                  errors.phone?.message ? `${errors.phone.message}` : ""
                }
                placeholder="Enter organization mobile number"
                type="number"
                fullWidth
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor="">
                Organization full address{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter organization full address"
                type="text"
                required
                fullWidth
                {...register("addressline1", {
                  required: "Organization full address is required",
                })}
                error={errors.addressline1 && Boolean(errors.addressline1)}
                helperText={
                  errors?.addressline1?.message
                    ? `${errors?.addressline1?.message}`
                    : ""
                }
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <label htmlFor="">Organization postal code</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter organization postal code"
                type="text"
                required
                fullWidth
                {...register("postalcode", {
                  required: "Postal code is required",
                })}
                error={errors.postalcode && Boolean(errors.postalcode)}
                helperText={
                  errors?.postalcode?.message
                    ? `${errors?.postalcode?.message}`
                    : ""
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <Box>
              <label htmlFor="">
                Upload organization logo ( max size 5mb )
              </label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="file"
                fullWidth
                onChange={(e: any) => setCOmpanyLogo(e.target.files[0])}
              />
            </Box>

            <Box mt={2}>
              <label htmlFor="">
                Organization email address{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <TextField
                id="outlined-basic"
                required
                variant="outlined"
                placeholder="Enter organization email address"
                type="email"
                fullWidth
                {...register("email", {
                  required: "Organization email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={errors.email && Boolean(errors.email)}
                helperText={
                  errors?.email?.message ? `${errors?.email?.message}` : ""
                }
              />
            </Box>
            <Box mt={2}>
              <label htmlFor=""> Organization website</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter organization website"
                type="url"
                fullWidth
                {...register("website")}
              />
            </Box>
          </Box>
        </Grid>
        <AddressSelect setAreaID={setAreaID} />
      </Grid>

      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: "end", mb: 1 }}>
          {mutation.isLoading ? (
            <Button disabled variant="contained" disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type="submit" variant="contained" disableElevation>
              Save
            </Button>
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default AddCompanyInformation;
